import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import SiteFooter from '../components/SiteFooter';
import HamburgerMenu from '../components/HamburgerMenu';
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';
import Logo from '../images/look-up-tour-logo.svg';
import Tagline from '../images/tagline.png';
import Will from '../images/will-infinity-large.png';
import Will2 from '../images/will-infinity-small.png';

function IndexPage({ pageContext }) {
  const page = pageContext;

  return (
    <Layout>
      <div>
        <HamburgerMenu />
        <section className="hero" id="mainHero">
          <nav role="navigation" id="nav-dropdown">
            <ul>
              <li class="button-outlined">
                <a href="#">Tour Locations</a>
                <ul class="dropdown">
                  <li>
                    <a href="https://billygraham.org/story/a-weight-lifted-idahoans-start-new-life-in-jesus-christ/">Idaho Falls</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <div class="hero-header">
            <div>
              <a href="/">
                <img src={Logo} alt="logo" id="logo" />
              </a>
              <img
                src={Tagline}
                alt="tagline"
                id="tagline"
                class="is-hidden-mobile"
              />
            </div>
            <img src={Will} alt="Will Graham" class="will-infinity" />
            <img src={Will2} alt="Will Graham" class="will-infinity-mobile" />
            <img
              src={Tagline}
              alt="tagline"
              id="tagline-mobile"
              class="is-hidden-desktop"
            />
          </div>
        </section>
        <div className="home-content">
          <MDXRenderer>{page.body}</MDXRenderer>
        </div>
        <SiteFooter />
      </div>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        pageTitle
        ogImage
        metaDesc
      }
      body
    }
  }
`;

export const Head = ({
  data: {
    mdx: {
      frontmatter: { title, ogImage, metaDesc },
    },
  },
}) => {
  return <SEO ogImage={ogImage} title={title} description={metaDesc} />;
};
